import React from 'react';
import TrackVisibility from 'react-on-screen';
import CountUp from 'react-countup';

const FaqOne = () => {
    return (
        <section className=" ">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-xl-6">
                        <div className="title-area me-xl-5 mb-20">
                            <h2 className="sec-title style2">常见问题</h2>
                        </div>
                        <div className="accordion-area accordion" id="faqAccordion">
                            <div className="accordion-card active">
                                <div className="accordion-header" id="collapse-item-1">
                                    <button
                                        className="accordion-button "
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapse-1"
                                        aria-expanded="true"
                                        aria-controls="collapse-1"
                                    >
                                        你们提供哪些服务?
                                    </button>
                                </div>
                                <div
                                    id="collapse-1"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="collapse-item-1"
                                    data-bs-parent="#faqAccordion"
                                >
                                    <div className="accordion-body">
                                        <p className="faq-text">
                                            我们提供广泛的网站制作服务, 包括网站设计和开发、长期技术支持与内容更新服务、搜索引擎优化。
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-card ">
                                <div className="accordion-header" id="collapse-item-2">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapse-2"
                                        aria-expanded="false"
                                        aria-controls="collapse-2"
                                    >
                                        需要多长时间完成一个项目?
                                    </button>
                                </div>
                                <div
                                    id="collapse-2"
                                    className="accordion-collapse collapse "
                                    aria-labelledby="collapse-item-2"
                                    data-bs-parent="#faqAccordion"
                                >
                                    <div className="accordion-body">
                                        <p className="faq-text">
                                            完成一个项目所需的时间取决于项目的复杂性和具体需求。一般来说，简单的网站通常需要一周之内(提供好文案、图片和文档)，而复杂的功能定制和多页面网站可能需要2-3个月。我们会与您详细讨论并提供准确的时间估算。
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-card ">
                                <div className="accordion-header" id="collapse-item-3">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapse-3"
                                        aria-expanded="false"
                                        aria-controls="collapse-3"
                                    >
                                        与您的机构合作需要多少费用?
                                    </button>
                                </div>
                                <div
                                    id="collapse-3"
                                    className="accordion-collapse collapse "
                                    aria-labelledby="collapse-item-3"
                                    data-bs-parent="#faqAccordion"
                                >
                                    <div className="accordion-body">
                                        <p className="faq-text">
                                            与我们的机构合作费用根据项目复杂性而定，基础网站起步价为￥40,000円，高级定制项目可能超过￥150,000円。欢迎咨询报价。
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="ms-xl-5">
                            <p className="counter-text">
                                我们提供专业的网站设计与开发, 打造独特在线形象,提供品牌价值。
                            </p>
                            <div className="counter-box_wrapper">
                                <div className="counter-box">
                                    <div className="counter-box_icon">
                                        <img
                                            src="assets/img/icon/counter-icon_1-1.svg"
                                            alt="icon"
                                        />
                                    </div>
                                    <div className="media-body">
                                        <h2 className="counter-box_number">
                                            <TrackVisibility once>
                                                {({isVisible}) =>
                                                    isVisible && (
                                                        <span className="counter-number">
                              <CountUp delay={0} start={0} end={10}/>
                              年+
                            </span>
                                                    )
                                                }
                                            </TrackVisibility>
                                        </h2>
                                        <p className="counter-box_text">行业经验</p>
                                    </div>
                                </div>
                                <div className="counter-box">
                                    <div className="counter-box_icon">
                                        <img
                                            src="assets/img/icon/counter-icon_1-2.svg"
                                            alt="icon"
                                        />
                                    </div>
                                    <div className="media-body">
                                        <h2 className="counter-box_number">
                                            <TrackVisibility once>
                                                {({isVisible}) =>
                                                    isVisible && (
                                                        <span className="counter-number">
                              <CountUp delay={0} start={0} end={500}/>
                              +
                            </span>
                                                    )
                                                }
                                            </TrackVisibility>
                                        </h2>
                                        <p className="counter-box_text">项目案例</p>
                                    </div>
                                </div>
                                <div className="counter-box">
                                    <div className="counter-box_icon">
                                        <img
                                            src="assets/img/icon/counter-icon_1-3.svg"
                                            alt="icon"
                                        />
                                    </div>
                                    <div className="media-body">
                                        <h2 className="counter-box_number">
                                            <TrackVisibility once>
                                                {({isVisible}) =>
                                                    isVisible && (
                                                        <span className="counter-number">
                              7 × 24小时
                            </span>
                                                    )
                                                }
                                            </TrackVisibility>
                                        </h2>
                                        <p className="counter-box_text">技术支持</p>
                                    </div>
                                </div>
                                <div className="counter-box">
                                    <div className="counter-box_icon">
                                        <img
                                            src="assets/img/icon/counter-icon_1-4.svg"
                                            alt="icon"
                                        />
                                    </div>
                                    <div className="media-body">
                                        <h2 className="counter-box_number">
                                            <TrackVisibility once>
                                                {({isVisible}) =>
                                                    isVisible && (
                                                        <span className="counter-number">
                              100%
                            </span>
                                                    )
                                                }
                                            </TrackVisibility>
                                        </h2>
                                        <p className="counter-box_text">满意保障</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FaqOne;
