import React from 'react'
import Marquee from "react-fast-marquee";
const MarqueeTwo = () => {
    return (
        <div className="marquee-area bg-smoke">
            <div className="container-fluid p-0 overflow-hidden">
                <div className="slider__marquee">
                    <div className="marquee_mode">
                        <Marquee speed={100}>
                            <div className="item">

                                <img src="assets/img/icon/icons8-google-96.svg" alt="img"/>
                                <span className="text-stroke">Google</span>

                            </div>
                            <div className="item">

                                <img src="assets/img/icon/icons8-microsoft-96.svg" alt="img"/>
                                <span>Microsoft</span>

                            </div>
                            <div className="item">

                                <img src="assets/img/icon/icons8-wordpress.svg" alt="img"/>
                                <span className="text-stroke">WordPress</span>

                            </div>
                            <div className="item">

                                <img src="assets/img/icon/icons8-notepad++.svg" alt="img"/>
                                <span>Notepad++</span>

                            </div>

                            <div className="item">

                                <img src="assets/img/icon/icons8-douphp.png" alt="img"/>
                                <span className="text-stroke">DouPHP</span>

                            </div>


                            <div className="item">

                                <img src="assets/img/icon/icons8-alibaba.svg" alt="img"/>
                                <span>Alibaba</span>
                                
                            </div>
                        </Marquee>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default MarqueeTwo