import React, { useEffect, useState } from 'react';
import Preloader from '../elements/Preloader';
import HelmetReact from '../elements/HelmetReact';
import Breadcrumb from '../components/Breadcrumb';
import FooterThree from '../components/FooterThree';
import Newsletter from '../components/Newsletter';
import TeamAll from '../components/TeamAll';
import HeaderThree from '../components/HeaderThree';

const Team = () => {
    let [active, setActive] = useState(true);
    useEffect(() => {
        setTimeout(function() {
            setActive(false);
        }, 500);
    }, []);
    return (
        <>
            {/* Preloader */}
            {active === true && <Preloader/>}

            {/* Helmet */}
            <HelmetReact title={'我们的团队'}/>

            {/* HeaderThree */}
            <HeaderThree/>

            {/* Breadcrumb */}
            <Breadcrumb title="我们的团队"/>

            {/* TeamAll */}
            <TeamAll/>


            {/* Newsletter */}
            {/*<Newsletter />*/}

            {/* FooterThree */}
            <FooterThree/>

        </>
    );
};

export default Team;
