import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
const PortfolioThree = () => {
  let settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <div className="overflow-hidden">
        <div className="bg-max-container bg-theme radius-50">
          <div className="team-area-1 space">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6">
                  <div className="title-area text-center">
                    <h2 className="sec-title style2 text-white">
                      我们的团队{" "}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row gx-30 global-carousel team-slider3">
                <Slider {...settings}>
                  <div className="team-padding">
                    <div className="team-card style2">
                      <div className="team-card_img">
                        <img src="assets/img/team/team-1-1.png" alt="img" />
                      </div>
                      <div className="team-card_wrapp">
                        <div className="team-card_content">
                          <h4 className="team-card_title">
                            <Link to="/team-details">林宇恒</Link>
                          </h4>
                          <span className="team-card_desig">
                            产品经理
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="team-padding">
                    <div className="team-card style2">
                      <div className="team-card_img">
                        <img src="assets/img/team/team-1-2.png" alt="img" />
                      </div>
                      <div className="team-card_wrapp">
                        <div className="team-card_content">
                          <h4 className="team-card_title">
                            <Link to="/team-details">陈晓丹</Link>
                          </h4>
                          <span className="team-card_desig">
                            平面设计师
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="team-padding">
                    <div className="team-card style2">
                      <div className="team-card_img">
                        <img src="assets/img/team/team-1-3.png" alt="img" />
                      </div>
                      <div className="team-card_wrapp">
                        <div className="team-card_content">
                          <h4 className="team-card_title">
                            <Link to="/team-details">李浩</Link>
                          </h4>
                          <span className="team-card_desig">
                            全栈开发工程师
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioThree;
