import React from "react";

const WhyChoose = () => {
  return (
    <div className="wcu-area-2 ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="title-area text-center me-xl-4 ms-xl-4">
              <h2 className="sec-title style2">
                搭建网站，助力企业增值
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6">
            <div className="wcu-thumb">
              <div className="wcu-thumb-1">
                <img src="assets/img/normal/wcu-thumb-1-1.png" alt="img" />
              </div>
              <div className="wcu-shape jump">
                <img src="assets/img/normal/1.png" alt="img" />
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="wcu-card-wrap">
              <div className="wcu-card">
                <div className="icon">
                  <img src="assets/img/icon/wcu-icon_2-1.svg" alt="img" />
                </div>
                <div className="wcu-card-details">
                  <h4 className="wcu-card-title">
                    提升品牌形象
                  </h4>
                  <p className="wcu-card-text">
                    一个精美完善的网站能提升公司品牌形象{" "}
                  </p>
                </div>
              </div>
              <div className="wcu-card">
                <div className="icon">
                  <img src="assets/img/icon/wcu-icon_2-2.svg" alt="img" />
                </div>
                <div className="wcu-card-details">
                  <h4 className="wcu-card-title">
                    增加可见性
                  </h4>
                  <p className="wcu-card-text">
                    随时在线展示产品和服务,提高曝光率和访问量{" "}
                  </p>
                </div>
              </div>
              <div className="wcu-card">
                <div className="icon">
                  <img src="assets/img/icon/wcu-icon_2-3.svg" alt="img" />
                </div>
                <div className="wcu-card-details">
                  <h4 className="wcu-card-title">
                    扩展市场
                  </h4>
                  <p className="wcu-card-text">
                    可以接触全球范围内的潜在客户,打破地理限制
                  </p>
                </div>
              </div>
              <div className="wcu-card">
                <div className="icon">
                  <img src="assets/img/icon/wcu-icon_2-4.svg" alt="img" />
                </div>
                <div className="wcu-card-details">
                  <h4 className="wcu-card-title">降低运营成本</h4>
                  <p className="wcu-card-text">
                    比传统宣传方式成本低,实现更多自动化操作
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChoose;
