import React, { useEffect, useState } from 'react';
import Preloader from '../elements/Preloader';
import HelmetReact from '../elements/HelmetReact';
import Breadcrumb from '../components/Breadcrumb';
import FooterThree from '../components/FooterThree';
import Newsletter from '../components/Newsletter';
import ContactInner from '../components/ContactInner';
import HeaderThree from '../components/HeaderThree';

const Contact = () => {
    let [active, setActive] = useState(true);
    useEffect(() => {
        setTimeout(function() {
            setActive(false);
        }, 500);
    }, []);
    return (
        <>
            {/* Preloader */}
            {active === true && <Preloader/>}

            {/* Helmet */}
            <HelmetReact title={'联系我们'}/>

            {/* HeaderThree */}
            <HeaderThree/>

            {/* Breadcrumb */}
            <Breadcrumb title="联系我们"/>

            {/* ContactInner */}
            <ContactInner/>

            {/* Newsletter */}
            {/*<Newsletter />*/}

            {/* FooterThree */}
            <FooterThree/>

        </>
    );
};

export default Contact;
