import React from 'react';
import { Link } from 'react-router-dom';

const FooterThree = () => {
    return (
        <footer
            className="footer-wrapper footer-layout3"
            style={{backgroundImage: 'url(assets/img/bg/footer-bg3-1.png)'}}
        >
            <div className="container">
                <div className="widget-area">

                    <div className="widget footer-widget widget-about">
                        <p className="footer-text mb-0 cs-text-style-1">
                            十五载匠心赋能，精细化链路拉齐，提升品牌心智，全方位开拓流量高地！
                        </p>
                    </div>

                </div>
            </div>
            <div className="copyright-wrap">
                <div className="container">
                    <div className="row justify-content-between align-items-center flex-column-reverse flex-lg-row gap-4">

                        <div className="col-xl-6 row justify-content-md-start justify-content-center">
                            <p className="copyright-text">
                                © <Link to="#">和風構築 </Link> 2024 | All Rights Reserved
                            </p>
                        </div>
                        
                        <div className="col-xl-6">
                            <div className="footer-links justify-content-md-end justify-content-center">
                                <Link to="/about">关于我们</Link>
                                <Link to="/service">服务内容</Link>
                                <Link to="/project">客户案例</Link>
                                <Link to="/pricing">获取报价</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </footer>

    );
};

export default FooterThree;