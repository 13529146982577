import React from 'react'
import { Link } from 'react-router-dom'

const PricingInner = () => {
    return (
        <div className="pricing-area-2 space">
            <div className="container">
                <div className="row gy-4 justify-content-center">
                    <div className="col-xl-4 col-md-6">
                        <div className="pricing-card">
                            <div className="pricing-card-price-wrap">
                                <h3 className="pricing-card_price">
                                    <span className="currency">￥</span>40,000 円
                                </h3>
                            </div>
                            <div className="pricing-card-icon-wrap">
                                <h4 className="pricing-card_title">基础启航套餐</h4>
                                <div className="pricing-card_icon">
                                    <img src="assets/img/icon/picing-icon_2-2.svg" alt="img" />
                                </div>
                            </div>
                            <div className="pricing-card-details">
                                <div className="checklist">
                                    <ul>
                                        <li>
                                            <i className="fas fa-angle-double-right" />
                                            基本页面
                                        </li>
                                        <li>
                                            <i className="fas fa-angle-double-right" />
                                            简单的图片和文字上传
                                        </li>
                                        <li>
                                            <i className="fas fa-angle-double-right" />
                                            基础 SEO 设置
                                        </li>
                                        <li>
                                            <i className="fas fa-angle-double-right" />
                                            1个月的免费维护
                                        </li>
                                    </ul>
                                </div>
                                <Link className="global-btn" to="/contact">
                                    即刻拥有 <img src="assets/img/icon/right-icon2.svg" alt="Bizmaster" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="pricing-card">
                            <div className="pricing-card-price-wrap">
                                <h3 className="pricing-card_price">
                                    <span className="currency">￥</span>69,800 円
                                </h3>
                            </div>
                            <div className="pricing-card-icon-wrap">
                                <h4 className="pricing-card_title">专业优化套餐</h4>
                                <div className="pricing-card_icon">
                                    <img src="assets/img/icon/picing-icon_2-3.svg" alt="img" />
                                </div>
                            </div>
                            <div className="pricing-card-details">
                                <div className="checklist">
                                    <ul>
                                        <li>
                                            <i className="fas fa-angle-double-right" />
                                            专业响应式设计
                                        </li>
                                        <li>
                                            <i className="fas fa-angle-double-right" />
                                            商业页面
                                        </li>
                                        <li>
                                            <i className="fas fa-angle-double-right" />
                                            SEO 优化
                                        </li>
                                        <li>
                                            <i className="fas fa-angle-double-right" />
                                            3个月的免费维护
                                        </li>
                                    </ul>
                                </div>
                                <Link className="global-btn" to="/contact">
                                    即刻拥有 <img src="assets/img/icon/right-icon2.svg" alt="Bizmaster" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="pricing-card">
                            <div className="pricing-card-price-wrap">
                                <h3 className="pricing-card_price">
                                    <span className="currency">￥</span>150,000 円
                                </h3>
                            </div>
                            <div className="pricing-card-icon-wrap">
                                <h4 className="pricing-card_title">高级定制套餐</h4>
                                <div className="pricing-card_icon">
                                    <img src="assets/img/icon/picing-icon_2-4.svg" alt="img" />
                                </div>
                            </div>
                            <div className="pricing-card-details">
                                <div className="checklist">
                                    <ul>
                                        <li>
                                            <i className="fas fa-angle-double-right" />
                                            全面定制服务
                                        </li>
                                        <li>
                                            <i className="fas fa-angle-double-right" />
                                            复杂功能 ( 如电商系统 )
                                        </li>
                                        <li>
                                            <i className="fas fa-angle-double-right" />
                                            自主后台管理系统
                                        </li>
                                        <li>
                                            <i className="fas fa-angle-double-right" />
                                            一年免费维护和更新
                                        </li>
                                    </ul>
                                </div>
                                <Link className="global-btn" to="/contact">
                                    即刻拥有 <img src="assets/img/icon/right-icon2.svg" alt="Bizmaster" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PricingInner