import React from "react";
import { Link } from "react-router-dom";

const HeroThree = () => {
  return (
    <div
      className="hero-wrapper bg-smoke hero-3"
      id="hero"
      style={{ backgroundImage: "url(assets/img/hero/hero_bg_3_1.png)" }}
    >
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-xl-6">
            
          </div>
          <div className="col-xl-6">
            <div className="hero-style3">
              <h1 className="hero-title">
                网站 <span className="fw-normal">策划·设计·制作</span>
                <span className="fw-normal">一站式服务</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroThree;
