import React from 'react';
import TrackVisibility from 'react-on-screen';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';

const AboutThree = () => {
    let settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1200,
                settings: {slidesToShow: 4},
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    arrows: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    arrows: false,
                },
            },
        ],
    };
    return (
        <>
            {' '}
            <div className="about-area-3 space">
                <div className="container">
                    <div className="row gy-40 gx-60 align-items-center">
                        <div className="col-xl-6">
                            <div className="about-thumb3">
                                <div className="img1">
                                    <img src="assets/img/normal/about_3-1.jpg" alt="img"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="title-area mb-20">
                                <h2 className="sec-title style2">
                                    网站策划、设计与制作一站式服务
                                </h2>
                            </div>
                            <div className="skill-feature style2 position-relative">
                                <h3 className="skill-feature_title">高效交付</h3>
                                <div className="progress">
                                    <div className="progress-bar" style={{width: '100%'}}></div>
                                    <div className="progress-value">
                                        <span className="counter-number">100</span>%
                                    </div>
                                </div>
                            </div>
                            <div className="skill-feature style2 position-relative">
                                <h3 className="skill-feature_title">满意度提升</h3>
                                <div className="progress">
                                    <div className="progress-bar" style={{width: '100%'}}></div>
                                    <div className="progress-value">
                                        <span className="counter-number">100</span>%
                                    </div>
                                </div>
                            </div>
                            <div className="skill-feature style2 position-relative">
                                <h3 className="skill-feature_title">行业领先标准</h3>
                                <div className="progress">
                                    <div className="progress-bar" style={{width: '100%'}}></div>
                                    <div className="progress-value">
                                        <span className="counter-number">100</span>%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overflow-hidden">
                <div className="bg-max-container bg-smoke radius-50">

                    {/* Feature Area 4  */}

                    <div className="feature-area-4 space">
                        <div className="container">
                            <div className="row align-items-end justify-content-between">
                                <div className="col-lg-6">
                                    <div className="title-area">
                                        <h2 className="sec-title style2">
                                            我们的优势
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row gy-40 justify-content-center">
                                <div className="col-lg-12 col-md-6">
                                    <div className="feature-card">
                                        <h4 className="feature-card-title">
                                            <Link to="/service">
                                                快速上线
                                            </Link>
                                        </h4>
                                        <p className="feature-card-text">
                                            最快当天内完成，确保你能尽早申请法人口座
                                        </p>
                                        <div className="btn-wrap">
                                            <Link
                                                to="/service"
                                                className="global-btn style-border"
                                            >
                                                了解更多 <i className="fas fa-arrow-right ms-2"/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6">
                                    <div className="feature-card">
                                        <h4 className="feature-card-title">
                                            <Link to="/service">专业设计</Link>
                                        </h4>
                                        <p className="feature-card-text">
                                            根据你的业务需求，量身定制专业网站
                                        </p>
                                        <div className="btn-wrap">
                                            <Link
                                                to="/service"
                                                className="global-btn style-border"
                                            >
                                                了解更多 <i className="fas fa-arrow-right ms-2"/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6">
                                    <div className="feature-card">
                                        <h4 className="feature-card-title">
                                            <Link to="/service">
                                                免费 SSL 证书
                                            </Link>
                                        </h4>
                                        <p className="feature-card-text">
                                            让您的网站没有“不安全”标识
                                        </p>
                                        <div className="btn-wrap">
                                            <Link
                                                to="/service"
                                                className="global-btn style-border"
                                            >
                                                了解更多 <i className="fas fa-arrow-right ms-2"/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutThree;
