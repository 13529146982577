import React from 'react';

const GoalArea = () => {
    return (
        <div className="goal-area space">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-6">
                        <div className="title-area">
                            <h2 className="sec-title style2">笃行致远，稳健前行</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="goal-tabs-wrapper">
                        <div
                            className="nav nav-tabs goal-tabs-tabs"
                            id="nav-tab"
                            role="tablist"
                        >
                            <button
                                className="nav-link"
                                id="nav-step1-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-step1"
                                type="button"
                            >
                                启航之旅
                            </button>
                            <button
                                className="nav-link"
                                id="nav-step2-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-step2"
                                type="button"
                            >
                                诚信为本
                            </button>
                            <button
                                className="nav-link active"
                                id="nav-step3-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-step3"
                                type="button"
                            >
                                使命必达
                            </button>
                            <button
                                className="nav-link"
                                id="nav-step4-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-step4"
                                type="button"
                            >
                                安全稳定
                            </button>
                        </div>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade" id="nav-step1" role="tabpanel">
                                <div className="goal-list_wrapper">
                                    <div className="goal-content_wrapp">
                                        <div className="goal-content">
                                            <h4 className="box-title">
                                                <img className="mr-5" src="assets/img/icon/angles-left.svg" alt="Bizmaster"/>
                                                WafuuWeb 应运而生
                                            </h4>
                                            <p>从法人口座到品牌展示，一站式网站建设解决方案</p>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check"/>
                                                        在赴日创业的旅程中，我们发现了一个独特的挑战：申请法人口座时，许多初创企业由于无法提供业务流水，面临困难。为了克服这个障碍，我们意识到，一个展示公司业务的网站可以成为有效的辅助工具。这一需求促成了 WafuuWeb 的诞生。
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check"/>
                                                        WafuuWeb 自成立以来，致力于为出海创业的华人提供专业的网站建设技术。我们的目标是帮助每一个创业者轻松拥有自己的公司网站，不仅满足法律和商业要求，更能展示他们的品牌价值。在这一过程中，我们深知创业旅途的艰辛，因此，我们承诺以创新和效率为基础，为客户提供一站式的解决方案。
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check"/>
                                                        通过我们的努力，WafuuWeb 希望成为创业路上的可靠伙伴，助力越来越多的华人在全球市场中崭露头角，开创属于他们的辉煌未来。
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-step2" role="tabpanel">
                                <div className="goal-list_wrapper">
                                    <div className="goal-content_wrapp">
                                        <div className="goal-content">
                                            <h4 className="box-title">
                                                <img className="mr-5" src="assets/img/icon/angles-left.svg" alt="Bizmaster"/>
                                                每个企业都应该有一个网站
                                            </h4>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check"/>
                                                        一项由 Verisign 进行的研究发现，84%的消费者认为，企业网站使企业显得更加可信。而68%的消费者更愿意从有网站的企业购买产品或服务，而不是那些仅在社交媒体上存在的企业。
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="goal-content_wrapp">
                                        <div className="goal-content">
                                            <h4 className="box-title">
                                                <img className="mr-5" src="assets/img/icon/angles-left.svg" alt="Bizmaster"/>
                                                全力以赴，解决客户问题
                                            </h4>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check"/>
                                                        在 WafuuWeb ，我们致力于为您的网站提供一切必要的支持。客户反馈在我们的研发过程中至关重要，以确保我们能够全面满足您的需求。
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="goal-content_wrapp">
                                        <div className="goal-content">
                                            <h4 className="box-title">
                                                <img className="mr-5" src="assets/img/icon/angles-left.svg" alt="Bizmaster"/>
                                                自产产品
                                            </h4>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check"/>
                                                        我们竭诚为您提供最佳的网站建设服务。从客户支持到产品设计、网站托管和开发，所有服务都由我们位于日本的敬业（且可爱的）团队提供。
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade active show"
                                id="nav-step3"
                                role="tabpanel"
                            >
                                <div className="goal-list_wrapper">
                                    <div className="goal-content_wrapp">
                                        <div className="goal-content">
                                            <h4 className="box-title mb-5">
                                                <img className="mr-5" src="assets/img/icon/angles-left.svg" alt="Bizmaster"/>
                                                全方位技术支持，助力您的品牌腾飞
                                            </h4>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check"/>
                                                        我们的目标是提供一系列的技术支持，以满足任何行业的业务需求，帮助海外华人拥有属于自己的公司网站。
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check"/>
                                                        无论项目大小，我们都确保从创意概念到最终上线的每一个环节都精益求精。
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check"/>
                                                        我们的团队由经验丰富的设计师和开发者组成，凭借对最新技术的深入理解和对细节的关注，为您的品牌打造独特而功能强大的网站。
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check"/>
                                                        选择我们，就是选择一种承诺：确保您的在线业务目标不仅被实现，更是超越预期。
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-step4" role="tabpanel">
                                <div className="goal-list_wrapper">
                                    <div className="goal-content_wrapp">
                                        <div className="goal-content">
                                            <h4 className="box-title">
                                                <img className="mr-5" src="assets/img/icon/angles-left.svg" alt="Bizmaster"/>
                                                提供SSL证书
                                            </h4>
                                            <p>在用户的浏览器和网站服务器之间建立加密连接。拥有SSL证书对网站和用户具有多方面的好处：</p>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check"/>
                                                        数据加密：加密传输的数据，保护敏感信息如登录凭证、信用卡详情）不被窃取或篡改。
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check"/>
                                                        提高信任度：浏览器地址栏显示小锁图标和“https://”，表明该网站是安全的。增强用户对网站的信任感，有助于减少购物车弃单率，提高在线交易转化率。
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check"/>
                                                        SEO 优势：搜索引擎，如 Google ，将 HTTPS 作为排名因素之一。因此，使用SSL证书可以帮助提升网站在搜索结果中的可见性。
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check"/>
                                                        符合行业标准:对于处理支付信息的网站，PCI DSS 要求必须使用 SSL 证书来保护交易。
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default GoalArea;
