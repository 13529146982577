import React from 'react'
import { Link } from 'react-router-dom'

const PortfolioAll = () => {
    return (
        <div className="portfolio-area-1 space">
            <div className="container">
                <div className="row gx-30 gy-60">
                    <div className="col-lg-6">
                        <div className="portfolio-card">
                            <div className="portfolio-card-thumb">
                                <img src="assets/demo_img/vip1.nee.net.png" alt="img" />
                            </div>
                            <div className="portfolio-card-details">
                                <div className="media-left">
                                    <h4 className="portfolio-card-details_title">
                                        <Link target="_blank" to="https://vip1.nee.net">
                                            装修
                                        </Link>
                                    </h4>
                                </div>
                                <Link target="_blank" to="https://vip1.nee.net" className="icon-btn">
                                    <img src="assets/img/icon/arrow-up-right.svg" alt="Bizmaster" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="portfolio-card">
                            <div className="portfolio-card-thumb">
                                <img src="assets/demo_img/vip2.nee.net.png" alt="img" />
                            </div>
                            <div className="portfolio-card-details">
                                <div className="media-left">
                                    <h4 className="portfolio-card-details_title">
                                        <Link target="_blank" to="https://vip2.nee.net">
                                            餐饮
                                        </Link>
                                    </h4>
                                </div>
                                <Link target="_blank" to="https://vip2.nee.net" className="icon-btn">
                                    <img src="assets/img/icon/arrow-up-right.svg" alt="Bizmaster" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="portfolio-card">
                            <div className="portfolio-card-thumb">
                                <img src="assets/demo_img/vip3.nee.net.png" alt="img" />
                            </div>
                            <div className="portfolio-card-details">
                                <div className="media-left">
                                    <h4 className="portfolio-card-details_title">
                                        <Link target="_blank" to="https://vip3.nee.net">
                                            电商
                                        </Link>
                                    </h4>
                                </div>
                                <Link target="_blank" to="https://vip3.nee.net" className="icon-btn">
                                    <img src="assets/img/icon/arrow-up-right.svg" alt="Bizmaster" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="portfolio-card">
                            <div className="portfolio-card-thumb">
                                <img src="assets/demo_img/vip4.nee.net.png" alt="img" />
                            </div>
                            <div className="portfolio-card-details">
                                <div className="media-left">
                                    <h4 className="portfolio-card-details_title">
                                        <Link target="_blank" to="https://vip4.nee.net">咨询</Link>
                                    </h4>
                                </div>
                                <Link target="_blank" to="https://vip4.nee.net" className="icon-btn">
                                    <img src="assets/img/icon/arrow-up-right.svg" alt="Bizmaster" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="portfolio-card">
                            <div className="portfolio-card-thumb">
                                <img src="assets/demo_img/vip5.nee.net.png" alt="img" />
                            </div>
                            <div className="portfolio-card-details">
                                <div className="media-left">
                                    <h4 className="portfolio-card-details_title">
                                        <Link target="_blank" to="https://vip5.nee.net">餐饮</Link>
                                    </h4>
                                </div>
                                <Link target="_blank" to="https://vip5.nee.net" className="icon-btn">
                                    <img src="assets/img/icon/arrow-up-right.svg" alt="Bizmaster" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="portfolio-card">
                            <div className="portfolio-card-thumb">
                                <img src="assets/demo_img/vip6.nee.net.png" alt="img" />
                            </div>
                            <div className="portfolio-card-details">
                                <div className="media-left">
                                    <h4 className="portfolio-card-details_title">
                                        <Link target="_blank" to="https://vip6.nee.net">租房</Link>
                                    </h4>
                                </div>
                                <Link target="_blank" to="https://vip6.nee.net" className="icon-btn">
                                    <img src="assets/img/icon/arrow-up-right.svg" alt="Bizmaster" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PortfolioAll